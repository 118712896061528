import React, { useEffect, useState } from "react";
import ourPartnersback from "../../images/ourPartnersback.png";

import { Link } from "react-router-dom";
import { GetAllPartners } from "../../ReduxToolkit/Slices/Partners/Partners.action";
import { useDispatch, useSelector } from "react-redux";
import { BaseUrl } from "../../ReduxToolkit/Apis";
import { LazyLoadImage } from "react-lazy-load-image-component";
import partnerlogodummy from "../../images/logoplaceholder.png";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";

const OurPartners = () => {
  const dispatch = useDispatch();
  const itemsPerPage = 12; // Number of items per page
  const [count, setCount] = useState(0); // Current offset
  const [page, setPage] = useState(1); // Current page number
  const partners = useSelector((store) => store?.Partners);
  const totalItems = partners?.AllPartners?.count || 0; // Total number of partners

  useEffect(() => {
    dispatch(GetAllPartners({ count }));
    window.scrollTo(0, 0);
  }, [count, dispatch]);

  const handleNext = () => {
    if (count + itemsPerPage < totalItems) {
      setCount((prevCount) => prevCount + itemsPerPage);
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrev = () => {
    if (count >= itemsPerPage) {
      setCount((prevCount) => prevCount - itemsPerPage);
      setPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <div>
      {/* --- Intro Section ---- */}
      <div className="w-full relative m-auto ">
        <img
          alt="offerbackgraundbanner"
          className="object-cover w-full h-[40vh] md:h-[60vh]"
          src={ourPartnersback}
        />
        <div className="absolute top-0 m-auto w-full h-full flex">
          <div className="text-zinc-50 w-full md:1/2 flex flex-col justify-center align-middle text-center m-auto">
            <h2 className="font-extrabold text-cxl md:text-c_xl">
              Our Partners
            </h2>
          </div>
        </div>
      </div>

      {/* ----- Display Partners -------------- */}
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-6 gap-y-6 md:gap-y-10 lg:gap-y-14 w-[90%] max-w-[1500px] mx-auto pt-6 md:pt-6 pb-8 md:pb-8 box-border">
        {partners?.AllPartners?.r?.map((partner, i) => (
          <Link to={`/ourpartners/${partner?.id}`} key={i}>
            <div className="shadow-[0px_1px_8px_0px_rgba(0,0,0,0.08)] group relative">
              <div className="h-28 md:h-52">
                <LazyLoadImage
                  effect="blur"
                  className="h-full w-full"
                  objectFit="cover"
                  src={
                    partner?.partner_logo
                      ? `${BaseUrl}${partner?.partner_logo}`
                      : partnerlogodummy
                  }
                  alt="partner logo"
                  height={"100%"}
                  width={"100%"}
                />
              </div>

              <div className="text-center border-t-2 p-4 md:p-4 flex justify-center align-middle bg-light-blue">
                <p className="text-md md:text-c_lg font-semibold overflow-hidden text-ellipsis whitespace-nowrap w-full">
                  {partner?.name ? partner?.name : "---"}
                </p>
              </div>

              <div className="absolute bottom-0 left-0 w-full border-b-4 border-primary-blue group-hover:border-b-8 duration-200 ease-linear" />
            </div>
          </Link>
        ))}
      </div>

      {/* Pagination Controls */}

      {totalItems > itemsPerPage && (
        <div className="flex justify-end items-center gap-2 w-[90%] max-w-[1500px] mx-auto py-8">
          <button
            onClick={handlePrev}
            disabled={count < itemsPerPage}
            className={` p-1 cursor-pointer border hover:border-black text-white  bg-primary-blue border-primary-blue rounded-full   ${
              count < itemsPerPage ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            <GrFormPreviousLink />
          </button>

          <p>
            Page: {Math.ceil(count / itemsPerPage) + 1} of{" "}
            {Math.ceil(totalItems / itemsPerPage)}
          </p>
          <button
            onClick={handleNext}
            disabled={count + itemsPerPage >= totalItems}
            className={` p-1 cursor-pointer border hover:border-black  text-white  bg-primary-blue border-primary-blue rounded-full   ${
              count + itemsPerPage >= totalItems
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
          >
            <GrFormNextLink />
          </button>
        </div>
      )}
    </div>
  );
};

export default OurPartners;
