import React from "react";
import logoFooter from "../../images/Logo.png";
import playStore from "../../images/playstoreTrans.png";
import appStore from "../../images/appstoreTrans.png";
import fbIcon from "../../images/Facebook.svg";
import tiktokIcon from "../../images/Tiktok.svg";
import instaIcon from "../../images/Instagram.svg";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <section className="w-full py-8 shadow-[0_-2px_14px_rgba(0,0,0,0.04)]">
      <div className="w-[90%] max-w-[1500px] mx-auto">
        <div className="flex flex-wrap gap-4 justify-between">
          <div className="w-[300px]  flex flex-col gap-4">
            <img
              className="w-[160px] xl:w-[200px]"
              src={logoFooter}
              alt="saveon logo"
            />
            <p className="text-desc-text font-medium md:text-[16px] xl:text-[18px]">
              Find exclusive coupons for local restaurants and services. Save
              money, enjoy great deals, and support local businesses
              effortlessly!
            </p>
          </div>
          <div className="flex flex-col gap-4 w-[300px] md:w-auto">
            <span className="font-semibold text-dark-blue text-[20px] xl:text-[22px]">
              Legal
            </span>
            <ul className="flex flex-col gap-4 text-[#6E818D] text-[16px] xl:text-[18px]">
              <li>
                <Link
                  to="/terms-condition"
                  className="text-desc-text hover:text-dark-blue"
                >
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link
                  to="/aboutus"
                  className="text-desc-text hover:text-dark-blue"
                >
                  FAQs
                </Link>
              </li>
              <li>
                <Link
                  to="/policy"
                  className="text-desc-text hover:text-dark-blue"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/ourpartners"
                  className="text-desc-text hover:text-dark-blue"
                >
                  Partners
                </Link>
              </li>
            </ul>
          </div>

          <div className="flex flex-col gap-4 w-[300px] md:w-auto">
            <span className="font-semibold text-dark-blue text-[20px] xl:text-[22px]">
              Contact
            </span>
            <ul className="flex flex-col gap-4 text-[#6E818D] text-[16px] xl:text-[18px]">
              <li>
                <Link
                  to={"/contactus"}
                  className="text-desc-text hover:text-dark-blue"
                >
                  Contact us
                </Link>
              </li>
              <li>
                <Link
                  to={"/aboutus"}
                  className="text-desc-text hover:text-dark-blue"
                >
                  About us
                </Link>
              </li>
            </ul>
          </div>

          <div className="flex md:flex-col gap-4">

          <a href="https://apps.apple.com/us/app/saveon-nz/id6740191980" target="_blank">
              
              <img
                alt="appStore"
                className="w-[130px] md:w-[160px] xl:w-[200px] transition-transform duration-500 transform hover:translate-y-1"
                src={appStore}
              />
              </a>
<a href="https://play.google.com/store/apps/details?id=com.saveon.app&hl=en_IN" target="_blank">

              <img
                alt="playStore"
                className="w-[130px] md:w-[160px] xl:w-[200px] transition-transform duration-500 transform hover:translate-y-1"
                src={playStore}
              />
</a>
          
          </div>
        </div>
        <div className="flex gap-2 mt-4 md:mt-0">
          <a
            href={"https://www.facebook.com/people/Saveon/61569490123612/"}
            className="cursor-pointer"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="w-[40px] cursor-pointer transition-transform duration-300 hover:scale-110 hover:border rounded-full border-primary-blue"
              src={fbIcon}
              alt="facebook"
            />
          </a>
          <a
            href={"https://www.instagram.com/saveon_nz/?next=%2Fsaveon%2F"}
            className="cursor-pointer"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="w-[40px] cursor-pointer transition-transform duration-300 hover:scale-110 hover:border rounded-full border-primary-blue"
              src={instaIcon}
              alt="facebook"
            />
          </a>
          <a
            href={"https://www.tiktok.com/@saveon_nz"}
            className="cursor-pointer"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="w-[40px] cursor-pointer transition-transform duration-300 hover:scale-110 hover:border rounded-full border-primary-blue"
              src={tiktokIcon}
              alt="facebook"
            />
          </a>
        </div>

        <hr className="mt-4" />
        <div>
          <p className="text-[#6E818D] text-[16px] xl:text-[18px] text-center mt-4">
            This site is protected by reCAPTCHA and the Google{" "}
            <Link
              to="/policy"
              className="text-dark-blue font-semibold hover:underline"
            >
              Privacy Policy{" "}
            </Link>
            and{" "}
            <Link
              to="/terms-condition"
              className="text-dark-blue font-semibold hover:underline"
            >
              Terms of Service{" "}
            </Link>
            apply.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
